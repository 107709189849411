<template>
  <div class="Orderdetails_body_top">
    <h4>{{$t("sabre.booking.order-detail-box.traveling")}}</h4>
    <p>
      <span v-if="adults">{{adults}} {{ adults > 1 ? $t("sabre.composition.adults") : $t("sabre.composition.adult")}} </span>
      <span v-if="children">| {{children}} {{adults > 1 ? $t("sabre.composition.children") : $t("sabre.composition.child")}}</span>
      <span v-if="babies">| {{babies}} {{babies > 1 ? $t("sabre.composition.infants") : $t("sabre.composition.infant")}}</span>
      <span v-if="seniors">| {{seniors}} {{seniors > 1 ? $t("sabre.composition.seniors") : $t("sabre.composition.senior")}}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'booking-order-detail-top',
  computed: {
    ...mapGetters({
      condition: 'GET_SABRE_ORDERED_CONDITION',
    }),
    adults() {
      const { condition } = this;
      return condition.passengerComposition.adults;
    },
    children() {
      const { condition } = this;
      return condition.passengerComposition.children;
    },
    babies() {
      const { condition } = this;
      return condition.passengerComposition.infants;
    },
    seniors() {
      const { condition } = this;
      return condition.passengerComposition.seniors;
    },
  },
};
</script>
